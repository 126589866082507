<template>
    <div class="home" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone': ''">
        <v-carousel class="carousel" cycle :show-arrows="false" hide-delimiters :height="'700'">
            <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                :src="item.src"
                :height="'700'"
            >
                <v-row
                    class="rowHome"
                    align="center"
                    justify="center"
                >
                    <img class="imgLine animate__animated animate__fadeInUp" src="../assets/line_top.png">
                    <div class="textCarousel"  
                        v-html="item.text">
                    </div>
                    <img class="imgLine animate__animated animate__fadeInDown" src="../assets/line_bottom.png">
                </v-row>
            </v-carousel-item>
        </v-carousel>

        <div class="cntInfo">
            <div class="infoData">26 Giugno 2022</div>
            <div class="infoLuogo">Countdown</div>
            <!-- <div class="infoLuogo">Via Tiberina, 06053 Deruta PG</div> -->
        </div>

        <div class="cntTime">
            <div class="time">
                <div class="number">{{ days }}</div>
                <div>Giorni</div>
            </div>
            <div class="time">
                <div class="number">{{ hours }}</div>
                <div>Ore</div>
            </div>
            <div class="time">
                <div class="number">{{ minutes }}</div>
                <div>Minuti</div>
            </div>
            <div class="time">
                <div class="number">{{ seconds }}</div>
                <div>Secondi</div>
            </div>
        </div>

        <img class="imgFiore" src="../assets/green-flower.png"/>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    head: {
        title: {
            inner: "Home",
        }
    },

    mounted() {
        var end = moment('2022-06-26');
        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
        var timer;
        timer = setInterval(() => {
            var now = moment();
            var distance = end - now;
            if (distance < 0) {
                clearInterval(timer);
                this.days = 0;
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
                return;
            }
            this.days = Math.floor(distance / _day);
            this.hours = Math.floor((distance % _day) / _hour);
            this.minutes = Math.floor((distance % _hour) / _minute);
            this.seconds = Math.floor((distance % _minute) / _second);
        }, 1000);
    },

    data() {
        return {
            items: [
                {
                    src: this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm' ? require('../assets/immagine per carosello 1 telefono.jpg') : require('../assets/immagine per carosello 1.jpg'),
                    text: "<div class='nomi sposa animate__animated animate__slideInLeft animate__slow'>Stella&nbsp;</div><div class='eCommerciale animate__animated animate__fadeIn'>&nbsp;&&nbsp;</div><div class='nomi sposo animate__animated animate__slideInRight animate__slower'>Alessandro</div>"
                },
                {
                    src: this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm' ? require('../assets/immagine per carosello 2 telefono.jpg') : require('../assets/immagine per carosello 2.jpg'),
                    text: "<div><div class='nomi animate__animated animate__slideInLeft animate__slow' style='font-size: 2.50rem; text-align: center'>Finalmente&nbsp;</div><div class='nomi animate__animated animate__slideInRight animate__slower'>&nbsp;Ci Sposiamo!</div></div>"
                },
                {
                    src: this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm' ? require('../assets/immagine per carosello 3 telefono.jpg') : require('../assets/immagine per carosello 3.jpg'),
                    text: "<div style='font-size: 2.50rem; text-align: center'><div class='animate__animated animate__slideInLeft animate__slow'>Coloro che vivono d'amore</div><div class='animate__animated animate__slideInRight animate__slower'>vivono d'eterno.</div></div></div>"
                }
            ],
            days: '',
            hours: '', 
            minutes: '',
            seconds: ''
        }
    },
}
</script>

<style lang="scss">
    @import "../app";

    .home{
        background-color: white;
        display: flex;
        flex-direction: column;
        .v-image__image--cover {
            filter: brightness(0.76);
        }
        .rowHome {
            flex-direction: column;
            height: 100%;
            .imgLine {
                opacity: 0;
                animation-duration: 4s;
                -webkit-animation-duration: 4s;
                animation-delay: 2s;
                -webkit-animation-delay: 2s;
            }
            .textCarousel {
                font-family: 'Alex Brush', cursive !important;
                color: white;
                font-size: 4.75rem !important;
                font-weight: 300;
                display: flex;
                justify-content: center;
                align-items: center;
                .sposa {
                    margin-left: 6rem;
                }
                .eCommerciale {
                    font-size: 7.75rem;
                }
                .animate__fadeIn {
                    animation-duration: 6s;
                }
            }
        }
        .cntInfo {
            margin: 20px;
            color: black;
            display: flex;
            flex-direction: column;
            align-items: center;
            .infoData {
                font-family: 'Alex Brush', cursive !important;
                font-size: 6.75rem;
            }
            .infoLuogo {
                font-size: 2.75rem;
            }
        }

        .cntTime {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .time {
                background-color: #000;
                color: white;
                margin: 20px;
                width: 100px;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .number {
                    font-size: 30px;
                }
                div {
                    font-size: 20px;
                }
            }
        }
    }

    .home.phone {
        .textCarousel {
            font-size: 2.75rem !important;
            flex-direction: column;
            .sposa {
                margin-left: unset !important;
            }
            .nomi {
                font-size: 3.50rem;
            }
            .eCommerciale {
                font-size: 3.00rem !important;
                text-align: center;
            }
        }

        .cntInfo {
            .infoData {
                font-size: 2.75rem;
            }
            .infoLuogo {
                font-size: 1rem;
            }
        }
    }
</style>